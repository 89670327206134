<template>
<div class="pt-1 px-25">
  
  <div>
    <label>Hash type</label>
    <b-form-select :options="hashTypes" text-field="label" value-field="type" v-model="agent.register_1.value"/>
  </div>

  <div>
    <custom-input
      v-if="variables"
      class="my-1"
      :possibleValues="variables"
      v-model="agent.register_2"
      advanced
    >
      <template #label>
        {{ $t(`agent.list.${i18nKey}.register_2.title`) }}
        
        <span class="float-right text-right mx-25 text-secondary">{{ $t(`agent.list.${i18nKey}.register_2.description`) }}</span>
      </template>
    </custom-input>
    <div v-else class="mb-2">
      <b-skeleton class="mb-25" width="25%" height="12px" />
      <b-skeleton class="mb-25" width="100%" height="37px" />
      <b-skeleton class="mb-25" width="25%" height="14px" />
    </div>
  </div>


  <div class="" align="center">
    <div class="text-secondary small mb-75">Generated Hash</div>
    <div class="mb-1">
      <b-iconstack>
        <b-icon icon="square" scale="2.3" stacked variant="secondary"/>
        <b-icon icon="hash" scale="2" stacked variant="favorite"/>
      </b-iconstack>
    </div>

    <div>
      <b-icon icon="arrow-down-short" scale="2.5" variant="success" />
    </div>
  </div>

  <div>
    <custom-input
      v-if="variables"
      class="my-1"
      :possibleValues="variables"
      v-model="agent.register_destiny"
      advanced
    >
      <template #label>
        {{ $t(`agent.list.${i18nKey}.register_destiny.title`) }}
        
        <span class="float-right text-right mx-25 text-secondary">{{ $t(`agent.list.${i18nKey}.register_destiny.description`) }}</span>
      </template>
    </custom-input>
    <div v-else class="mb-2">
      <b-skeleton class="mb-25" width="25%" height="12px" />
      <b-skeleton class="mb-25" width="100%" height="37px" />
      <b-skeleton class="mb-25" width="25%" height="14px" />
    </div>
  </div>
  
  
</div>
</template>

<script>
import {
  BLink,
  BSkeleton, 
  BFormCheckbox, 
  BButton, 
  BContainer, 
  BCol, 
  BRow, 
  BSidebar, 
  BIcon ,
  BIconstack,
  BFormSelect,
} from "bootstrap-vue";import { mapActions } from "vuex";
import { v4 as uuidv4 } from "uuid";
import DefaultAgent from "../DefaultAgent"
import VariablesPanel from "@/views/pages/middleware/MiddlewareManagement/Components/VariablesPanel.vue";
import CustomInput from "@/views/pages/middleware/MiddlewareManagement/Components/CustomInput.vue";
import NormalizedAgent from "@/custom/class/NormalizedAgent.js";
import HelperTooltip from '@/layouts/components/HelperTooltip';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import HashTypes from '@/custom/class/Enum/HashTypes.js';
import AgentsMixin from '../AgentsMixin'

export default {
  mixins: [AgentsMixin],

  components: {
    BButton,
    BContainer,
    BCol,
    BRow,
    BSidebar,
    VariablesPanel,
    CustomInput,
    BFormCheckbox,
    BIcon,
    BSkeleton,
    HelperTooltip,
    BLink,
    VuePerfectScrollbar,
    BIconstack,
    BFormSelect,
  },
  props: {
    middlewareID: {
      type: Number,
      required: true,
    },
    agentID: {
      type: Number,
      default: undefined,
    },
    value: {
      type: NormalizedAgent,
      default: undefined
    },
    uuid: {
      type: String,
      default: uuidv4(),
    },
    width: {
      type: String,
      default: "40%"
    },
    agentBlockID: {
      type: [ Number, String ],
      default: 1,
    },
    variables:{
      type: Object,
      default: undefined,
    }
  },
  data() {
    return {
      actionID: 10,//banana
      i18nKey: 'generate_hash',
      hashTypes: new HashTypes().items,
      defaultAgent: {
        id: null,
        enum_agent_action_id: this.actionID,
        agent_block_id: this.agentBlockID,
        register_1: {
          source: 7,
          value: ""
        },
        register_2: {
          source: 7,
          value: ""
        },
        register_destiny: {
          source: 10,
          value: ""
        },
        execution_order: 1,
        fatal_on_fail: true,
      },
      
      uuidMap: {},

      isSaving: false,
    };
  },
  computed: {
    agent: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    toSaveRegs() {
      return {
        register_1: true,
        register_2: true,
        register_destiny: true,
      }
    },
  },
  mounted() {
    this.init();

    this.$nextTick(()=>{

      if (!this.agent.register_1.value || this.agent.register_1.value.length == 0 ){
        this.agent.register_1.value = this.hashTypes[0].type
      }
    })
  },
  methods: {
    ...mapActions(["insertAgent", "updateAgent", "getAgentByID"]),
    init() {
      DefaultAgent.fetchAgentByID(this)
    },
    validate() {
      return DefaultAgent.validate(this)
    },
    getID(key) {
      if (this.uuidMap[key]) {
        return this.uuidMap[key];
      }
      const uuid = uuidv4();
      this.uuidMap[key] = uuid;

      return uuid;
    },
    toggleSidebar() {
      this.$root.$emit("bv::toggle::collapse", this.uuid);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
