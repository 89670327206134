export default class EncryptionTypes {
  constructor() {
    this.items = [
      {
        id: 1,
        type: "MD5",
        label:"MD5",
      },
      {
        id: 2,
        type: "SHA256",
        label:"SHA256",
      },
      {
        id: 3,
        type: "SHA1",
        label:"SHA1",
      },
      {
        id: 4,
        type: "RIPEMD160",
        label:"RIPEMD160",
      },
    ];
  }
}